import React from 'react'
import { css, SerializedStyles } from '@emotion/react'

import color from '@/constants/color'
import { mq } from '@/components/media/media'
import { ServiceType } from '@/typedir/types'

import none_Braces_BL from '@/images/none_Braces_BL.svg'
import { Anchor } from '@/components/common/anchor'

type ServiceCardProps = {
  service: ServiceType
  isLink?: boolean
  MoreDetail?: JSX.Element
  className?: string
  isRound?: boolean
  innerStyles?: {
    descriptionLayout?: SerializedStyles
    heading?: SerializedStyles
    description?: SerializedStyles
  }
}

export const ServiceCard = ({
  service,
  isLink = true,
  MoreDetail,
  className,
  isRound = false,
  innerStyles
}: ServiceCardProps): JSX.Element => {
  const Wrapper = isLink ? Anchor : 'div'

  return (
    <Wrapper className={className} href={service.url ? service.url : ''} css={cssCard(isRound)} isTargetBlank={true}>
      {
      service.image
       && 
      <div>
        <img src={service.image.url} alt="" />
      </div>
      }
      <div css={[cssDescriptionLayout, innerStyles?.descriptionLayout]}>
        <h3 css={[cssServiceHeading, innerStyles?.heading]}>{service.title}</h3>
        <p css={[cssServiceDescription, innerStyles?.description]}>
          {service.description}
        </p>
        {MoreDetail}
      </div>
    </Wrapper>
  )
}

const cssCard = (isRound: boolean) => css`
  display: block;
  padding: 0 16px;
  ${mq.pc} {
    height: 100%;
    padding: 0;
    background-color: ${color.white1};
    background-image: url(${none_Braces_BL});
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 24px auto;
    border-bottom-right-radius: ${isRound ? '24px' : '0'};
    box-shadow: 0 3px 30px ${color.black3};
  }
`

const cssDescriptionLayout = css`
  padding: 32px 16px 16px;
`

const cssServiceHeading = css`
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: 500;
  line-height: 1.6;
  color: ${color.black2};
  letter-spacing: 1px;
  ${mq.pc} {
    padding: 0 16px;
    margin: 0;
  }
`

const cssServiceDescription = css`
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.7;
  color: ${color.black1};
  letter-spacing: 1.4px;
  word-break: break-word;
  ${mq.pc} {
    margin: 16px;
    font-weight: 400;
    line-height: 1.9;
  }
`
